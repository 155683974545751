<template>
    <div id="search">
        <a href="">{{ tableData.name }}</a>
        <!-- 搜索框 -->
        <div class="head">
            <el-input placeholder="请输入查询" v-model="input" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="ItemQuery(input)"></el-button>
            </el-input>
            <div class="last-elements">
                <a href="/tool" style="color: #007bff;">返回首页</a>
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="c_name1" label="一级分类" style="width: 25%">
                </el-table-column>
                <el-table-column prop="c_name2" label="二级分类" style="width: 25%;">
                    <!-- <template slot-scope="{ row }"
            ><a href="" style="color: #2440b3">{{ row.c_name2 }}</a></template
          > -->
                </el-table-column>
                <el-table-column prop="c_name3" label="三级分类" style="width: 25%">
                    <!-- <template slot-scope="{ row }"
            ><a href="" style="color: #2440b3">{{ row.c_name3 }}</a></template
          > -->
                </el-table-column>
                <el-table-column prop="c_name" label="四级分类" style="width: 25%">
                    <template slot-scope="{ row }">
                        <!-- <a href="" style="color: #2440b3" @click="hhh()"></a> -->
                        <el-button type="text" @click="details(row)">{{ row.c_name }}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import factors from "../../utils/factors.json";
export default {
    name: "Search",

    data() {
        return {
            input: "",
            tableData: [],
            list: [],
            onelevel: [],
        };
    },
    created() {
        this.handlelist();
        this.autoClick();
    },
    methods: {
        details(row) {
            // console.log('111',row.c_name,row.downstream_emissions);
            const text = this.$router.resolve({
                name: 'itemview',
                query: {
                    name: row.c_name,
                    number: row.upstream_emissions
                }
            });
            window.open(text.href, '_blank');
        },
        autoClick() {
            // console.log(this.$route.params.id);
            this.input = this.$route.query.id
            // console.log(this.input,'this');
            this.ItemQuery(this.input)
        },
        handlelist() {
            function fn(data, catalogueNames = []) {
                const result = [];
                for (const value of data) {
                    if (value.data) {
                        catalogueNames.push({
                            c_name: value.c_name,
                            e_name: value.e_name,
                        });
                        result.push(...fn(value.data, catalogueNames));
                    } else {
                        // console.log(catalogueNames)
                        catalogueNames.forEach((item, index) => {
                            value["c_name" + (index + 1)] = item.c_name;
                            value["e_name" + (index + 1)] = item.e_name;
                        });
                        result.push(value);
                    }
                }
                catalogueNames.pop();
                return result;
            }
            this.list = fn(factors);
            // console.log(this.list, "this.list");
        },
        ItemQuery(input) {
            this.tableData = [];
            console.log(input);
            this.list.forEach((e) => {
                if (
                    e.c_name.includes(input) ||
                    e.c_name1.includes(input) ||
                    e.c_name2.includes(input) ||
                    e.c_name3.includes(input)
                ) {
                    // console.log(e);
                    this.tableData.push(e);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
#search {
    width: 1500px;
    margin: 0 auto;

    &>div {
        &.head {
            display: flex;
            margin: 15px auto;
            width: 1100px;

            .last-elements {
                width: 100px;
                height: 39px;
                margin-left: 20px;
                // background-color: red;
                padding: 10px;
            }
        }
    }
}
</style>
